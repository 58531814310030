<template>
  <div class="lesson" v-loading="pageLoading">

    <div class="title">个人信息</div>
    <div class="centerMain">
        <div class="content">
          <p><span>姓名：</span>{{info.name}}</p>
          <p><span>性别：</span>{{info.gender == 1 ? '男' : '女'}}</p>
          <p><span>手机号：</span>{{info.mobile}}</p>
          <p><span>身份证号：</span>{{info.cid}}</p>
        </div>
        <img :src="info.cover || 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/placeImg.jpg'"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      info:{},
      pageLoading: true,
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.onLoad);
    this.getData();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
    getTag(index) {
      this.tag = index;
    },
    async getData() {
      this.pageLoading = true;
        let res = await this.$request.userInfo();
        this.pageLoading = false;
        if (res.code == 0) {
          this.info = res.data;
        } else {
          this.$toast(res.msg);
        }
        
    },

    
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  font-family: "SourceHanSans";
  .title{
    
    font-size: 24px;
    color: #242424;
    font-weight: bold;
    border-bottom: 1px dashed #f0f0f0;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
  }
  .centerMain{
    width: 1000px;
    height: 231px;
    margin: 30px auto 0;
    background: #f9f9f9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    .content{
      flex: 1;
      p{
        color: #666666;
        font-size: 16px;
        display: inline-block;
        width: 33.3%;
        line-height: 60px;
        span{
          color: #333333;
          display: inline-block;
          width: 106px;
        }
      }
    }
    img{
      width: 124px;
      height: 173px;
      border-radius: 8px;
      overflow: hidden;
      object-fit: cover;
    }
  }
}
</style>

<style lang="scss">
body {
  background: #fff;
}
</style>
